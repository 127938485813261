@import 'partials/variables';
@import 'partials/mixins';

body {
  height: calc(100vh - 64px);
}

#root {
  height: 100%;
  max-height: 100vh;
}

.leaflet-container {
  height: 400px;
  width: 100%;
}

.full-view-section {
  position: relative;
  height: 100vh;
}

.footer-section {
  position: relative;
  height: 50vh;
}

.link {
  color: blue;
  text-decoration: none;

  &:visited {
    color: blue;
  }
}

.button-icon-gmail {
  width: 20px;
  height: 20px;
}

.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-5px);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

#animated-example {
  width: 20px;
  height: 20px;
  background-color: red;
  position: relative;
  top: 100px;
  left: 100px;
  border-radius: 50%;
}

// todo: check
hr {
  position: relative;
  top: 92px;
  left: -300px;
  width: 200px;
}

.react-tel-input .form-control {
  border-radius: 2px !important;
  height: 56px;
  border: 1px solid rgb(253, 240, 234) !important;
}

.react-tel-input .form-control:focus {
  box-shadow: none !important;
  border: 2px solid rgb(63, 167, 140) !important;
}

.react-tel-input .form-control.form-control-error {
  box-shadow: none !important;
  border-color: #ff1744 !important;
}

.react-tel-input .form-control.form-control-error:focus {
  box-shadow: none !important;
  border-width: 2px !important;
}

.react-tel-input .form-control:hover {
  box-shadow: none !important;
  border: 1px solid #cccccc !important;
}

.react-tel-input .form-control:focus {
  border-width: 2px !important;
}

.full-width {
  width: 100% !important;
}
